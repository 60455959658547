body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #222;
  color: #bbb;
  overflow: hidden;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
input,
button,
select,
textarea {
  font-size: 16px;
  font-family: Itim, sans-serif;
}

* {
  box-sizing: border-box;
  outline: none;
}

::selection {
  background-color: #ddd;
  color: #222;
}

a {
  color: inherit;
  text-decoration: underline;
}
a:hover {
  text-shadow: black 0 0 10px;
}
